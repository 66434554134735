import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from 'src/app/core/auth.service';
import { ISettings } from 'src/app/shared/common.models';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private LIMIT = 10;
  private DEFAULT_FEATURES: any = {
    "apk": {
      "_self": false,
      "appName": false,
      "download": false
    },
    "custom": {
      "countdown": false
    },
    "menu": {
      "_self": true,
      "footer": true,
      "menuList": true
    },
    "notification": {
      "_self": true,
      "scheduled": false
    },
    "settings": {
      "_self": false,
      "splash": false,
      "urlChange": false
    }
  };

  constructor(private afs: AngularFireDatabase, public authService: AuthService) {

  }

  getDefaultSystemFeatures(): any {
    return this.DEFAULT_FEATURES;
  }

  getFeatures(): Promise<firebase.database.DataSnapshot> {
    return this.afs.database.ref(this.authService.ProviderName + '/features')
      .once('value');
  }

  getSettings(): Promise<firebase.database.DataSnapshot> {
    return this.afs.database.ref(this.authService.ProviderName + '/info')
      .once('value');
  }

  getSettingsByKey(key: string): Promise<firebase.database.DataSnapshot> {
    return this.afs.database.ref(this.authService.ProviderName + '/info/' + key)
      .once('value');
  }

  updateSettings(settings: ISettings, removeNulls: boolean = true): Promise<any> {

    if (removeNulls)
      settings = this.removeNullAndUndefinedFields(settings);

    if (!this.authService.ProviderName)
      return new Promise((resolve, reject) => {
        reject('provider name not set');
      });

    //TODO - geçici kaldırılacak ileride
    return this.afs.database.ref(this.authService.ProviderName + '/info')
      .update(settings)

    // return this.afs.database.ref(this.authService.ProviderName + '/settings')
    //   .update(settings)
  }

  updateKeyValSettings(settings: any, removeNulls: boolean = true): Promise<any> {

    if (removeNulls)
      settings = this.removeNullAndUndefinedFields(settings);

    if (!this.authService.ProviderName)
      return new Promise((resolve, reject) => {
        reject('provider name not set');
      });

    return this.afs.database.ref(this.authService.ProviderName + '/info')
      .update(settings)
  }


  updateKeyValFooterSettings(footerValues: any, removeNulls: boolean = true): Promise<any> {

    if (removeNulls)
      footerValues = this.removeNullAndUndefinedFields(footerValues);

    if (!this.authService.ProviderName)
      return new Promise((resolve, reject) => {
        reject('provider name not set');
      });

    return this.afs.database.ref(this.authService.ProviderName + '/info/footer')
      .update(footerValues)
  }

  getWebViewUrl(): Promise<firebase.database.DataSnapshot> {
    return this.afs.database.ref(this.authService.ProviderName + '/info/url')
      .once('value');
  }

  updateWebViewUrl(url: string): Promise<any> {
    if (!this.authService.ProviderName)
      return new Promise((resolve, reject) => {
        reject('provider name not set');
      });

    return this.afs.database.ref(this.authService.ProviderName + '/info')
      .update({ 'url': url })
    // return this.afs.database.ref(this.authService.ProviderName)
    //   .update({ 'webview-url': url })
  }

  private removeNullAndUndefinedFields(data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] == false)
          continue;
        if (!data[key])
          delete data[key];
      }
    }

    return data;
  }

  updateDefaultMenuSettings(menuName: string, value: boolean): Promise<any> {
    if (!this.authService.ProviderName)
      return new Promise((resolve, reject) => {
        reject('provider name not set');
      });

    const data = {};
    data[menuName] = value;

    return this.afs.database.ref(this.authService.ProviderName + '/info/menu')
      .update(data)
  }


}
