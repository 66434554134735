import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
// import { AngularFireObject, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase';
import { AngularFireStorage } from 'angularfire2/storage';


export class Upload {

  $key: string;
  file: File;
  name: string;
  url: string;
  progress: number;
  createdAt: Date = new Date();

  constructor(file: File) {
    this.file = file;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private db: AngularFireDatabase, private storage: AngularFireStorage) { }

  private _basePath: string = '/uploads';
  // uploads: FirebaseObjectObservable<Upload[]>;

  pushUpload(basePath: string, upload: Upload, cb: Function) {
    if (!basePath)
      basePath = this._basePath;

    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef.child(`${basePath}/${upload.file.name}`).put(upload.file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // upload in progress
        upload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      (error) => {
        // upload failed
        console.log(error)
      },
      () => {
        // upload success
        upload.url = uploadTask.snapshot.downloadURL
        upload.name = upload.file.name

        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          cb(downloadURL);
        });
        // this.saveFileData(basePath, upload)
      }
    );
  }

  private saveFileData(basePath: string, upload: Upload) {
    this.db.list(`${basePath}/`).push(upload);
  }

  deleteFile(downloadUrl: string): Promise<any> {
    // return firebase.firestore().doc(url).delete();
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }

  deleteUpload(upload: Upload) {
    this.deleteFileStorage(upload.name);
    // this.deleteFileData(upload.$key)
    //   .then(() => {
    //     this.deleteFileStorage(upload.name)
    //   })
    //   .catch(error => console.log(error))
  }

  // Deletes the file details from the realtime db
  private deleteFileData(key: string) {
    return this.db.list(`${this._basePath}/`).remove(key);
  }

  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileStorage(name: string) {
    let storageRef = firebase.storage().ref();
    storageRef.child(`${this._basePath}/${name}`).delete()
  }

}
